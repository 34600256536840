import "core-js/modules/es.array.push.js";
import { defineStore } from 'pinia';
export const useStore = defineStore('counter', () => {
  const name = ref('李敏'); // 定义 ref

  const addname = () => {
    name.value = '李敏 | 敏敏子';
  };
  const previewSrcList = item => {
    if (!item) {
      return;
    }
    if (item.indexOf('base64') > -1) {
      return [item];
    }
    let real_src_list = item.split(",");
    let srcList = [];
    real_src_list.forEach(item => {
      return srcList.push(item);
    });
    return srcList; // 去掉可能的空格
  };
  return {
    name,
    // 返回 ref 以供外部使用
    addname,
    previewSrcList
  };
});