import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import installElementPlus from './plugins/element'
import router from './router'
import SignaturePad from 'vue-signature-pad';
import VueSignaturePad from "vue-signature-pad";
import './plugins/axios'

import {ref , reactive} from 'vue'
import 'element-plus/theme-chalk/dark/css-vars.css';
import 'element-plus/dist/index.css';

import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
// VXETable.config({
//     theme: 'dark'
// })

//地图
import VueAMap, {initAMapApiLoader} from '@vuemap/vue-amap';import '@vuemap/vue-amap/dist/style.css';
window._AMapSecurityConfig = {
    securityJsCode: "3c8229e7988ec9bacf5fe6f99f106474"
};
initAMapApiLoader({
    key: 'f1c5e2551fb0573b2953bdfe06e0c5ce',
    plugins: ['AMap.Autocomplete', 'AMap.PlaceSearch',
        'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar',
        'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor',
        'AMap.Geocoder'
    ],
    Loca:{
     version: '2.0.0'
    } // 如果需要使用loca组件库，需要加载Loca
})
import VueTianditu from "vue-tianditu";



const app = createApp(App).use(router);


app.use(createPinia())
app.use(router)
app.use(VueTianditu, {
    v: "4.0", //目前只支持4.0版本
    tk: "bd673ed365a52ef5fbbe41f2fbf1643e"
});
app.use(SignaturePad)
app.use(VueSignaturePad)
app.use(VXETable)
app.use(VueAMap);
installElementPlus(app)


app.mount('#app')
